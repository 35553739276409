import { Fbq, Gtag } from '@xylabs/react-pixel'
// eslint-disable-next-line import-x/no-internal-modules
import { AppRouter } from '@xyo-network/os-react-app/app-router'
import React, { lazy } from 'react'

const OsApp = lazy(() => import(/* webpackChunkName: "OsApp" */ '@xyo-network/os-react-app'))

// eslint-disable-next-line import-x/no-internal-modules
const View = lazy(() => import(/* webpackChunkName: "View" */ '@xyo-network/os-react-app/view'))

Fbq.init('1653480428329003')
Gtag.init('G-SLKCXVB59J', 'AW-989965544', ['node.xyo.network'])

export const App: React.FC = () => {
  return (
    <AppRouter env={import.meta.env} View={View} OsApp={OsApp} />
  )
}
